




















































import { Component, Vue, PropSync } from "vue-property-decorator";
import { VDatePicker } from "vuetify/lib/components/VDatePicker/VDatePicker";
import format from "date-fns/format";

@Component
export default class CosmopolDatePicker extends Vue {
  @PropSync("dates", { required: false, default: () => [], type: Array })
  private _dates: string[];

  private menu = false;

  private checkDates(): void {
    this.$nextTick(() => {
      return (
        this._dates.length === 2 &&
        this._dates[0] > this._dates[1] &&
        this._dates.reverse()
      );
    });
  }

  private get dateValue(): string {
    let dates = [];
    if (this._dates && this._dates.length) {
      dates = this._dates.map((d) => format(new Date(d), "dd/MM/yyyy"));
    }
    return dates.join(" - ");
  }

  private onSave(datesArr: string[]): void {
    (this.$refs.menu as VDatePicker).save(datesArr);
    this.$emit("on-dates-change");
  }
}
